import React from "react"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Heading, Text, Styled } from "theme-ui"

const Page = ({ location }) => (
  <Layout>
    <SEO
      location={location}
      title="Datenschutz"
      description="Informationen wie wir auf unserer Website mit Ihren Daten umgehen."
    />
    <Heading as="h1" variant="siteTitle">
      Datenschutzerklärung
    </Heading>
    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      1. Datenschutz auf einen Blick
    </Heading>
    <Text variant="subheading" sx={{ pt: 2 }}>
      Allgemeine Hinweise
    </Text>
    <Text variant="default">
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
      Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
      Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
      identifiziert werden können. Ausführliche Informationen zum Thema
      Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
      Datenschutzerklärung.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Datenerfassung auf unserer Website
    </Text>
    <Text variant="default" sx={{ fontWeight: "bold" }}>
      Wer ist verantwortlich für die Datenerfassung auf dieser Website?
    </Text>

    <Text variant="default">
      Die Datenverarbeitung auf dieser Website erfolgt durch den
      Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
      Website entnehmen.
    </Text>

    <Text variant="default" sx={{ fontWeight: "bold" }}>
      Wie erfassen wir Ihre Daten?
    </Text>

    <Text variant="default">
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
      Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular
      eingeben.
    </Text>

    <Text variant="default">
      Andere Daten werden automatisch beim Besuch der Website durch unsere
      IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
      Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
      Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
      betreten.
    </Text>

    <Text variant="default" sx={{ fontWeight: "bold" }}>
      Wofür nutzen wir Ihre Daten?
    </Text>

    <Text variant="default">
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
      Website zu gewährleisten. Andere Daten können zur Analyse Ihres
      Nutzerverhaltens verwendet werden.
    </Text>

    <Text variant="default" sx={{ fontWeight: "bold" }}>
      Welche Rechte haben Sie bezüglich Ihrer Daten?
    </Text>

    <Text variant="default">
      Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
      Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
      erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
      Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum
      Thema Datenschutz können Sie sich jederzeit unter der im Impressum
      angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
      Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
    </Text>

    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      2. Allgemeine Hinweise und Pflichtinformationen
    </Heading>
    <Text variant="subheading" sx={{ pt: 2 }}>
      Datenschutz
    </Text>
    <Text variant="default">
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
      sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
      entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerklärung.
    </Text>

    <Text variant="default">
      Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
      Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
      identifiziert werden können. Die vorliegende Datenschutzerklärung
      erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
      erläutert auch, wie und zu welchem Zweck das geschieht.
    </Text>

    <Text variant="default">
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
      Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
      lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
      möglich.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Hinweis zur verantwortlichen Stelle
    </Text>
    <Text variant="default">
      Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
      ist:
    </Text>

    <Text variant="default">
      Uschtrin Verlag
      <br />
      Sandra Uschtrin
      <br />
      Gänsbrunnenweg 1a<br />
      D-85652 Pliening
      <br />
    </Text>

    <Text variant="default">
      Telefon: <Styled.a href="tel:+49 89 / 21528793">+49 89 / 21528793</Styled.a>
      <br />
      E-Mail:{" "}
      <Styled.a href="mailto:info@uschtrin.de">info@uschtrin.de</Styled.a>
    </Text>

    <Text variant="default">
      Verantwortliche Stelle ist die natürliche oder juristische Person, die
      allein oder gemeinsam mit anderen über die Zwecke und Mittel der
      Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
      Ä.) entscheidet.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Widerruf Ihrer Einwilligung zur Datenverarbeitung
    </Text>
    <Text variant="default">
      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
      Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
      jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
      uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
      bleibt vom Widerruf unberührt.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Beschwerderecht bei der zuständigen Aufsichtsbehörde
    </Text>
    <Text variant="default">
      Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
      Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
      Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
      Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
      seinen Sitz hat.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Recht auf Datenübertragbarkeit
    </Text>
    <Text variant="default">
      Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder
      in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
      einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu
      lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
      Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
      ist.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      SSL- bzw. TLS-Verschlüsselung
    </Text>
    <Text variant="default">
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
      vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
      Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung.
      Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
      des Browsers von “http://” auf “https://” wechselt und an dem
      Schloss-Symbol in Ihrer Browserzeile.
    </Text>

    <Text variant="default">
      Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
      die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Auskunft, Sperrung, Löschung
    </Text>
    <Text variant="default">
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft über Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
      personenbezogene Daten können Sie sich jederzeit unter der im Impressum
      angegebenen Adresse an uns wenden.
    </Text>

    <Heading as="h2" sx={{ pt: 4, pb: 2 }}>
      3. Datenerfassung auf unserer Website
    </Heading>
    <Text variant="subheading" sx={{ pt: 2 }}>
      Cookies
    </Text>
    <Text variant="default">
      Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
      richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
      sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner
      abgelegt werden und die Ihr Browser speichert.
    </Text>

    <Text variant="default">
      Die meisten der von uns verwendeten Cookies sind so genannte
      “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
      gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
      diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
      nächsten Besuch wiederzuerkennen.
    </Text>

    <Text variant="default">
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
      Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
      Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
      das automatische Löschen der Cookies beim Schließen des Browser
      aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
      dieser Website eingeschränkt sein.
    </Text>

    <Text variant="default">
      Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
      oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
      Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs.
      1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes
      Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
      optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
      Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
      in dieser Datenschutzerklärung gesondert behandelt.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Server-Log-Dateien
    </Text>
    <Text variant="default">
      Der Provider der Seiten erhebt und speichert automatisch Informationen in
      so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
      übermittelt. Dies sind:
    </Text>

    <Text variant="default">
      <ul>
        <li sx={{ m: 0 }}>Browsertyp und Browserversion</li>
        <li sx={{ m: 0 }}>verwendetes Betriebssystem</li>
        <li sx={{ m: 0 }}>Referrer URL</li>
        <li sx={{ m: 0 }}>Hostname des zugreifenden Rechners</li>
        <li sx={{ m: 0 }}>Uhrzeit der Serveranfrage</li>
        <li sx={{ m: 0 }}>IP-Adresse</li>
      </ul>
    </Text>
    <Text variant="default">
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
      vorgenommen.
    </Text>

    <Text variant="default">
      Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der
      die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Kontaktformular
    </Text>
    <Text variant="default">
      Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
      E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines
      Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular
      ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung
      Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene
      technische Administration gespeichert und verwendet.
    </Text>

    <Text variant="default">
      Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes
      Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f
      DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so
      ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
      DSGVO.
    </Text>

    <Text variant="default">
      Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht,
      dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der
      betroffene Sachverhalt abschließend geklärt ist und sofern keine
      gesetzlichen Aufbewahrungspflichten entgegenstehen.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Verarbeiten von Daten (Kunden- und Vertragsdaten)
    </Text>
    <Text variant="default">
      Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie
      für die Begründung, inhaltliche Ausgestaltung oder Änderung des
      Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
      Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten
      zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
      (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
      erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
      ermöglichen oder abzurechnen.
    </Text>

    <Text variant="default">
      Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
      Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
      Aufbewahrungsfristen bleiben unberührt.
    </Text>

    <Text variant="subheading" sx={{ pt: 2 }}>
      Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und
      Warenversand
    </Text>
    <Text variant="default">
      Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
      Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der Lieferung
      der Ware betrauten Unternehmen oder das mit der Zahlungsabwicklung
      beauftragte Kreditinstitut. Eine weitergehende Übermittlung der Daten
      erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich
      zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
      Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
    </Text>

    <Text variant="default">
      Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
      die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
      vorvertraglicher Maßnahmen gestattet.
    </Text>
  </Layout>
)

export default Page
